<div>
  <p class="copyright">
    🚀 Idee und Umsetzung: <a href="https://zeilenwerk.ch">Zeilenwerk</a><br />
    📊 Daten: <a href="https://smartvote.ch">smartvote</a>
  </p>
</div>

<style>
  div {
    padding-bottom: 30px;
    font-size: 0.8em;
  }
</style>
