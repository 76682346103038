<script>
  import { forceSimulation, forceX, forceY, forceCollide } from "d3-force"
  import CandidateDot from "./CandidateDot.svelte"
  import CandidateComment from "./CandidateComment.svelte"
  import Distribution from "./Distribution.svelte"

  const width = 1000
  const height = 280

  export let candidates = []

  let renderedDotsYes = []
  let renderedDotsRatherYes = []
  let renderedDotsRatherNo = []
  let renderedDotsNo = []

  let hoveredCandidate = null
  let selectedCandidateId = null

  $: candidateCopy = candidates.map((c, i) =>
    candidateCopy ? { ...candidateCopy[i], ...c } : { ...c }
  )

  $: forceSimulation()
    .force("forceX", forceX().x(200))
    .force("forceY", forceY().y(140))
    .force("forceCollide", forceCollide().radius(10).iterations(3))
    .nodes(candidateCopy.filter((c) => c.answer === 100))
    .on("tick", () => {
      // update the renderedDots reference to trigger an update
      renderedDotsYes = [...candidateCopy.filter((c) => c.answer === 100)]
    })

  $: forceSimulation()
    .force("forceX", forceX().x(400))
    .force("forceY", forceY().y(140))
    .force("forceCollide", forceCollide().radius(10).iterations(3))
    .nodes(candidateCopy.filter((c) => c.answer === 75))
    .on("tick", () => {
      // update the renderedDots reference to trigger an update
      renderedDotsRatherYes = [...candidateCopy.filter((c) => c.answer === 75)]
    })

  $: forceSimulation()
    .force("forceX", forceX().x(600))
    .force("forceY", forceY().y(140))
    .force("forceCollide", forceCollide().radius(10).iterations(3))
    .nodes(candidateCopy.filter((c) => c.answer === 25))
    .on("tick", () => {
      // update the renderedDots reference to trigger an update
      renderedDotsRatherNo = [...candidateCopy.filter((c) => c.answer === 25)]
    })

  $: forceSimulation()
    .force("forceX", forceX().x(800))
    .force("forceY", forceY().y(140))
    .force("forceCollide", forceCollide().radius(10).iterations(3))
    .nodes(candidateCopy.filter((c) => c.answer === 0))
    .on("tick", () => {
      // update the renderedDots reference to trigger an update
      renderedDotsNo = [...candidateCopy.filter((c) => c.answer === 0)]
    })

  function handleHoverEvent(event) {
    hoveredCandidate = event.detail
  }
  const handleCandidateSelect = (event) => {
    selectedCandidateId = event.detail.id
  }
</script>

<Distribution
  nofYes={renderedDotsYes.length}
  nofRatherYes={renderedDotsRatherYes.length}
  nofNoAnswer={4}
  nofRatherNo={renderedDotsRatherNo.length}
  nofNo={renderedDotsNo.length}
/>

<figure class="c">
  <svg width="100%" viewBox="0 0 {width} {height}">
    <text x="200" y={height-15} text-anchor="middle" fill="grey">Ja</text>
    <text x="400" y={height-15} text-anchor="middle" fill="grey">Eher Ja</text>
    <text x="600" y={height-15} text-anchor="middle" fill="grey">Eher Nein</text>
    <text x="800" y={height-15} text-anchor="middle" fill="grey">Nein</text>
    {#each [...renderedDotsYes, ...renderedDotsRatherYes, ...renderedDotsRatherNo, ...renderedDotsNo] as candidate, i}
      <CandidateDot
        on:hoverEvent={handleHoverEvent}
        on:click={handleCandidateSelect}
        {candidate}
        isActive={selectedCandidateId && candidate.id === selectedCandidateId}
      />
    {/each}
  </svg>
</figure>

<CandidateComment
  candidate={hoveredCandidate ||
    (selectedCandidateId &&
      candidates.find((c) => c.id === selectedCandidateId))}
/>

<style>
  .c {
    position: relative;
    width: 100%;
    margin: 0;
    border-bottom: 1px lightgrey solid;
  }
</style>
