<!-- custom made by corinne felix -->
<svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20 0C14.6956 0 9.60865 2.10713 5.85796 5.85796C2.10713 9.6085 0 14.6958 0 20C0 25.3042 2.10713 30.3914 5.85796 34.142C9.6085 37.8929 14.6958 40 20 40C23.5105 40 26.9595 39.0759 29.9999 37.3206C33.0403 35.565 35.5649 33.0405 37.3206 29.9999C39.0759 26.9595 40 23.5105 40 20C40 14.6956 37.8929 9.60865 34.142 5.85796C30.3915 2.10713 25.3042 0 20 0V0ZM16.168 29.9998L13.8321 27.6678L21.4999 20L13.8321 12.3322L16.168 10.0002L26.168 20.0001L16.168 29.9998Z"
    fill="black"
  />
</svg>
