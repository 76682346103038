<script>
  export let numberOfCandidatesWithProfile
  export let totalElectedCandidates
</script>

<h1>Wie tickt der neu gewählte Grosse Rat Bern?</h1>
<p>
  Klicke dich durch die smartvote-Fragen, finde heraus wie sich die gewählten
  Parlamentarier*innen positionieren. So sieht man, für welche Fragen es klare
  Mehrheiten gibt und welche Themen kontrovers diskutiert werden.
</p>
<p>
  Jeder Punkt repräsentiert einen gewählten Kandidierenden, der die
  smartvote-Fragen beantwortet hat. Fahre mit der Maus über einen Punkt um mehr
  zu erfahren. Klicke auf einen Punkt um diese Person anzuwählen und ihr durch
  den Fragebogen zu folgen.
</p>
<p>
  Ein Ring
  <svg width="16" height="16">
    <circle r={6} cx="8" cy="8" class="candidate sp comment" />
  </svg>
  bedeutet, dass der oder die Parlamentarier*in einen Kommentar (Begründung) zu dieser
  Frage abgeben hat.
</p>
<p>
  {numberOfCandidatesWithProfile} von {totalElectedCandidates} der gewählten Kandidierenden
  haben bei smartvote teilgenommen.
</p>

<style>
  h1 {
    font-size: 1em;
    font-weight: 600;
  }
  p {
    font-size: 0.8em;
  }
  .candidate {
    stroke-width: 0px;
  }
  .candidate.comment {
    stroke-width: 3px;
    fill: white;
  }
  .sp {
    fill: #ff0000;
    stroke: #ff0000;
  }
</style>
