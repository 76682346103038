<script>
  export let nofYes = 0
  export let nofRatherYes = 0
  export let nofNoAnswer = 4
  export let nofRatherNo = 0
  export let nofNo = 0

  $: totalParliamentarians =
    nofYes + nofRatherYes + nofNoAnswer + nofRatherNo + nofNo

  let noAnswerWidth
</script>

<div class="container">
  {#if nofYes > 0}
    <div
      style={`width: ${(nofYes / totalParliamentarians) * 1000}px`}
      class="yes"
    >
      <div class="label">Ja</div>
    </div>
  {/if}
  {#if nofRatherYes > 0}
    <div
      style={`width: ${(nofRatherYes / totalParliamentarians) * 1000}px`}
      class="ratherYes"
    >
      <div class="label">Eher Ja</div>
    </div>
  {/if}
  {#if nofNoAnswer > 0}
    <div
      style={`width: ${(nofNoAnswer / totalParliamentarians) * 1000}px`}
      class="noAnswer"
      bind:clientWidth={noAnswerWidth}
    >
      <div class="label label-noAnswer">Keine Antwort</div>
      <div style={`left: ${noAnswerWidth / 2 - 1}px`} class="pointer" />
    </div>
  {/if}
  {#if nofRatherNo > 0}
    <div
      style={`width: ${(nofRatherNo / totalParliamentarians) * 1000}px`}
      class="ratherNo"
    >
      <div class="label">Eher Nein</div>
    </div>
  {/if}
  {#if nofNo > 0}
    <div
      style={`width: ${(nofNo / totalParliamentarians) * 1000}px`}
      class="no"
    >
      <div class="label">Nein</div>
    </div>
  {/if}
  <div class="middle"><div class="middle-label">50%</div></div>
</div>

<style>
  div {
    height: 20px;
    transition: width 0.5s ease-in-out;
  }
  .container {
    background-color: rgba(255,255,255,.7);
    max-width: 100%;
    display: flex;
    height: 40px;
    padding: 30px 0 30px 0;
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
  .yes {
    background-color: #4997e0;
  }
  .ratherYes {
    background-color: #9ac8f3;
  }
  .noAnswer {
    background-color: #bdbdbd;
    position: relative;
  }
  .ratherNo {
    background-color: #f8a0a9;
  }
  .no {
    background-color: #f04c5c;
  }
  .middle {
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 30px;
    bottom: 50px;
    background-color: black;
  }
  .label {
    margin-top: 22px;
    color: grey;
    font-size: 12px;
    overflow: visible;
    white-space: nowrap;
  }
  .label-noAnswer {
    color: #bdbdbd;
    position: absolute;
    bottom: -40px;
    left: -23px;
  }
  .pointer {
    background-color: #bdbdbd;
    position: absolute;
    width: 2px;
    top: 20px;
  }
  .middle-label {
    font-size: 12px;
    position: absolute;
    top: -17px;
    left: -5px;
  }
</style>
