<script>
  import * as questionnaire from "./questionnaire.json"
  import * as candidates from "./candidatesFiltered.json"

  import Answers from "./components/Answers.svelte"
  import Question from "./components/Question.svelte"
  import MakingOf from "./components/MakingOf.svelte"
  import Drawer from "./components/Drawer.svelte"
  import Info from "./components/Info.svelte"
  import MobileWarning from "./components/MobileWarning.svelte"

  let questionIndex = 0

  const questions = questionnaire.data.election.questionnaire.categories
    .reduce((previous, current) => previous.concat(current.questions), [])
    .filter((q) => q.type === "StandardQuestion")
  $: question = questions[questionIndex]

  const electedCandidates = candidates.default

  $: electedCandidatesWithProfile = electedCandidates
    .filter((c) => !!c.answers)
    .map((c) => ({
      id: c.ID_Candidate,
      answer: c.answers.find((a) => a.questionId.toString() === question.id)
        .answer,
      comment: c.answers.find((a) => a.questionId.toString() === question.id)
        .comment,
      name: c.firstname + " " + c.lastname,
      party: c.party_short,
      gender: c.gender,
      yearOfBirth: c.year_of_birth,
      zipCode: c.zip,
      city: c.city,
      photo: c.photos && c.photos[0],
      incumbent: c.incumbent,
      LINK_portrait: c.LINK_portrait
    }))
    .sort((a, b) => a.party - b.party)

  const handleQuestionChange = (event) => {
    questionIndex = event.detail
  }
</script>

<main>
  <MobileWarning />
  <h1 class="main-title">Grosser Rat Bern</h1>
  <Question
    {questions}
    {questionIndex}
    on:questionChanged={handleQuestionChange}
  />
  <Answers candidates={electedCandidatesWithProfile} />
  <Drawer>
    <Info
      numberOfCandidatesWithProfile={electedCandidatesWithProfile.length}
      totalElectedCandidates={electedCandidates.length}
    />
    <MakingOf />
  </Drawer>
</main>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  main {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
  }
  h1 {
    color: black;
    font-size: 1.5em;
    font-weight: normal;
  }
  .main-title {
    margin-top: 2em;
  }
</style>
