<script>
  import { createEventDispatcher } from "svelte"
  import ChevronLeftFilled from "./icons/ChevronLeftFilled.svelte"
  import ChevronRightFilled from "./icons/ChevronRightFilled.svelte"
  export let questions = []
  export let questionIndex = 0

  const dispatch = createEventDispatcher()

  const nextQuestion = () => {
    dispatch("questionChanged", questionIndex + 1)
  }
  const previousQuestion = () => {
    dispatch("questionChanged", questionIndex - 1)
  }
</script>

<div class="question-container">
  <button
    class="question-nav-button"
    style={questionIndex === 0 && "visibility: hidden"}
    on:click={previousQuestion}><ChevronLeftFilled /></button
  >
  <div class="question-text-container">
    <p class="question-text">{questions[questionIndex].text}</p>
    <p class="question-counter">
      Frage {questionIndex + 1} /
      {questions.length}
    </p>
    
  </div>
  <button
    class="question-nav-button"
    style={questionIndex === questions.length - 1 && "visibility: hidden"}
    on:click={nextQuestion}><ChevronRightFilled /></button
  >
</div>

<style>
  .question-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0 -1em 0;
  }
  .question-text-container {
    flex-grow: 1;
  }
  .question-nav-button {
    background-color: white;
    margin: 0;
    border: none;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .question-nav-button:first-child {
    margin: 0 10px 0 0;
  }
  .question-nav-button:last-child {
    margin: 0 0 0 10px;
  }
  .question-nav-button:hover {
    background: #d0d0d0;
  }
  .question-counter {
    color: grey;
    margin: 0;
  }
  .question-text {
    margin: 0;
    min-height: 90px;
    font-weight: normal;
    font-size: 1.4em;
    line-height: 1.3;
  }
</style>
