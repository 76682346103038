<script>
  import ChevronLeftFilled from "./icons/ChevronLeftFilled.svelte"
  import InfoCircleFilled from "./icons/InfoCircleFilled.svelte"
  import Info from "./Info.svelte"

  let isExpanded = false
  let hasBeenExpanded = false

  const expand = () => {
    isExpanded = !isExpanded
    hasBeenExpanded = true
  }

  let windowWidth
</script>

<svelte:window bind:innerWidth={windowWidth} />

{#if windowWidth > 475}
  <div class:isExpanded class="drawer" class:mobile={windowWidth < 1020}>
    <button class:isExpanded on:click={expand}
      ><ChevronLeftFilled width={24} height={24} fill="#f0f0f0" /></button
    >
    <div class="info-hint" class:hasBeenExpanded on:click={expand}>
      <b>Mehr Informationen</b>
    </div>
    <div class="info" on:click={expand}>
      <InfoCircleFilled />
    </div>
    <div class:isExpanded class="drawer-content"><slot /></div>
  </div>
{/if}

<style>
  .drawer {
    position: relative;
    background-color: white;
    position: fixed;
    right: -420px;
    top: 0px;
    height: 100vh;
    width: 400px;
    text-align: left;
    padding: 10px 20px;
    transition: right 0.7s cubic-bezier(0.38, 0, 0.6, 1);
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 15px 10px rgba(150, 150, 150, 0.25);
    z-index: 1001;
  }
  .drawer.mobile {
    right: -440px;
  }
  .drawer.isExpanded {
    right: 0px;
  }
  .drawer-content {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: visible;
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.38, 0, 0.6, 1);
  }
  .drawer-content.isExpanded {
    opacity: 1;
  }
  button {
    background-color: grey;
    color: #f0f0f0;
    position: absolute;
    padding: 0;
    top: 40px;
    left: -12px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0;
    border: 1px lightgrey solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.7s cubic-bezier(0.38, 0, 0.6, 1);
  }
  .drawer.mobile > button {
    left: -32px;
  }
  button:hover {
    border: 1px #4997e0 solid;
    background-color: white;
    color: #4997e0;
  }
  button.isExpanded {
    transform: rotate(180deg);
  }
  .info {
    fill: #4997e0;
    color: #4997e0;
    position: absolute;
    top: 10px;
    left: -12px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
  .drawer.mobile > .info {
    left: -32px;
  }
  .info-hint {
    position: absolute;
    left: -184px;
  }
  .info-hint.hasBeenExpanded {
    display: none;
  }
  .drawer.mobile > .info-hint {
    left: -204px;
  }
</style>
