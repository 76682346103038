<script>
  import WarningFilled from "./icons/WarningFilled.svelte"
  import ScreenRotation from "./icons/ScreenRotation.svelte"
  import Close from "./icons/Close.svelte"
  let windowWidth
  let hasBeenClosed = false

  const handleClose = () => {
    hasBeenClosed = true
  }
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div class="mobile-hint" class:hasBeenClosed class:mobile={windowWidth < 1020}>
  <div>
    <ScreenRotation />
  </div>
  <p>
    Am besten betrachten Sie diese Applikation auf Ihrem Desktop oder im
    Landscape Mode.
  </p>
  <button on:click={handleClose}><Close /></button>
</div>

<style>
  .mobile-hint {
    position: relative;
    display: none;
    width: 100hw;
    background-color: #faf7c9;
    padding: 10px 40px 10px 20px;
    z-index: 2000;
  }
  .mobile-hint.mobile {
    display: flex;
    align-items: center;
  }
  .mobile-hint.mobile.hasBeenClosed {
    display: none;
  }
  p {
    text-align: left;
    margin: 0 0 0 20px;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
</style>
