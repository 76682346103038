<script>
  import { createEventDispatcher } from "svelte"
  const dispatch = createEventDispatcher()

  const move = (x, y) => `transform: translate(${x}px, ${y}px`
  export let candidate = null
  export let isActive = false
  export let showTrace = true

  let isHovered = false

  function mouseOver() {
    isHovered = true
    dispatch("hoverEvent", candidate)
  }
  function mouseLeave() {
    isHovered = false
    dispatch("hoverEvent", null)
  }
  const handleClick = () => {
    dispatch("click", candidate)
  }

  $: trace =
    isActive && candidate && candidate.x
      ? [{ x: candidate.x, y: candidate.y }, ...trace].slice(0, 20)
      : []
</script>

{#if candidate}
  {#if isHovered || isActive}
    {#if showTrace}
      <polyline
        class="trace"
        points={trace.map((p) => `${p.x},${p.y}`).join(" ")}
      />
    {/if}
    <circle
      class="hover"
      class:isActive
      r={11}
      style={move(candidate.x, candidate.y)}
    />
  {/if}
  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
  <circle
    on:mouseover={mouseOver}
    on:mouseleave={mouseLeave}
    on:click={handleClick}
    style={move(candidate.x, candidate.y)}
    r={6}
    class:hasComment={candidate.comment}
    class={"candidate " + candidate.party.toLowerCase().replace(" ", "")}
  />
{/if}

<style>
  circle.hover {
    fill: #d0d0d0;
  }
  circle.isActive {
    fill: grey;
  }
  circle.candidate {
    cursor: pointer;
    stroke-width: 0px;
  }
  circle.candidate.hasComment {
    stroke-width: 3px;
    fill: white;
  }
  .trace {
    stroke-linecap: "round";
    fill: none;
    stroke: grey;
    stroke-width: 6;
    opacity: 0.5;
    stroke-linejoin: "round";
  }

  .sp,
  .psr,
  .es, .psa {
    fill: #ff0000;
    stroke: #ff0000;
  }
  .diemitte {
    fill: #ff7d00;
    stroke: #ff7d00;
  }
  .svp {
    fill: #006a49;
    stroke: #006a49;
  }
  .fdp,
  .prr {
    fill: #255ff6;
    stroke: #255ff6;
  }
  .evp {
    fill: #f6a700;
    stroke: #f6a700;
  }
  .glp,
  .jglp {
    fill: #A0A000;
    stroke: #A0A000;
  }
  .grüne,
  .gfl,
  .gb,
  .jg {
    fill: #17a25a;
    stroke: #17a25a;
  }
  .edu {
    fill: #93648A;
    stroke: #93648A;
  }
  .ja\!,
  .al {
    fill: rgb(198, 0, 118);
    stroke: rgb(198, 0, 118);
  }

  /*
  $color_party_linke: #c02828 !default;
  $color_party_lega: #5a2b87 !default;
  $color_party_sd: #1d1d1d !default;
  $color_party_csp: #0ea7e6 !default;
  $color_party_pda: #c02828 !default;*/
</style>
