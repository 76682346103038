<script>
  import ExternalLink from "./icons/ExternalLink.svelte"
  import { fade } from "svelte/transition"
  export let candidate = null
</script>

<div class="container">
  {#if candidate}
    <div
      class="sub-container"
      class:hasComment={candidate.comment}
      in:fade={{ duration: 200 }}
    >
      <div class="portrait-container">
        <div
          class="photo"
          style={candidate.photo
            ? `background-image: url(${candidate.photo})`
            : ""}
        />
        <p>{`${candidate.name} (${candidate.party})`}</p>
        <a
          class="portrait-link"
          href={`${candidate.LINK_portrait}`}
          target="_blank">smartvote Profil <ExternalLink /></a
        >
      </div>
      {#if candidate.comment}
        <div>
          <p class="comment">
            {`«${candidate.comment}»`}
          </p>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .container {
    min-height: 250px;
    max-width: 800px;
    margin: 1em auto;
    padding-bottom: 100px;
  }
  .sub-container {
    display: flex;
    justify-content: center;
  }
  .sub-container.hasComment {
    justify-content: flex-start;
  }
  .portrait-container {
    white-space: nowrap;
    transition: 2s;
  }
  p {
    margin: 0.5em 0;
  }
  .photo {
    background-color: rgb(207, 207, 207);
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .comment {
    text-align: left;
    line-height: 150%;
    padding-left: 20px;
  }
  .portrait-link {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
  }
</style>
