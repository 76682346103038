<!-- custom made by corinne felix -->
<script>
  export let width = 40
  export let height = 40
</script>

<svg
  width={width}
  height={height}
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20 40C25.3044 40 30.3914 37.8929 34.142 34.142C37.8929 30.3915 40 25.3042 40 20C40 14.6958 37.8929 9.60865 34.142 5.85796C30.3915 2.10713 25.3042 0 20 0C16.4895 0 13.0405 0.924137 10.0001 2.67941C6.95966 4.43497 4.43511 6.95952 2.67941 10.0001C0.924137 13.0405 0 16.4895 0 20C0 25.3044 2.10713 30.3914 5.85796 34.142C9.6085 37.8929 14.6958 40 20 40V40ZM23.832 10.0002L26.1679 12.3322L18.5001 20L26.1679 27.6678L23.832 29.9998L13.832 19.9999L23.832 10.0002Z"
    fill="currentColor"
  />
</svg>
